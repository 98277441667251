'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ReactAnimatedWeather = require('./ReactAnimatedWeather');

var _ReactAnimatedWeather2 = _interopRequireDefault(_ReactAnimatedWeather);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _ReactAnimatedWeather2.default;